<template>
  <div class="ym-page">
    <!-- 显示nothing -->
    <div class="data-list nothing"
         v-if="hasInfo === 2">
      <img src="../chainData/img/nothing.png"
           alt="">
      <span class="nothing-text"
            style="margin-left: -18px;">暂无修改记录</span>
    </div>
    <!-- 显示loading -->
    <div class="data-list nothing" style="margin-top:300px;"
         v-if="hasInfo === 1">
      <i class="el-icon-loading"
         style="font-size:50px;"></i>
      <span class="nothing-text" style="margin-left:2px;">加载中</span>
    </div>
    <!-- 账户信息 -->
    <status-detail :datas="searchValue" v-on:status-say="getFromStatus"></status-detail>
  </div>
</template>

<script>
import StatusDetail from './component/statusDetail.vue'
export default {
  components: {
    StatusDetail
  },
  name: 'workStatusDetails',
  data() {
    return {
      hasInfo: 1, // 默认1:显示loading/2:显示暂无数据/3:显示数据
      searchValue: {
        searchKey: '' // 搜索条件
      }
    }
  },
  created() {},
  mounted() {
    if (this.$route.query.searchKey) {
      this.searchValue.searchKey = this.$route.query.searchKey
    }
  },
  methods: {
    getFromStatus(value) {
      console.log(value)
      this.hasInfo = value
    }
  }
}
</script>

<style lang="less" scoped>
.ym-page {
  .data-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.nothing {
      padding-bottom: 20px;
      margin: 200px auto;
      font-size: 14px;
      img {
        width: 180px;
        height: 181px;
      }
      .nothing-text {
        margin-left: -18px;
        margin-top: 10px;
        color: #888888;
      }
    }
  }
}
</style>
